import React, { Component } from 'react';
import logo from './img/logo.png';
import phoneIllustration from './img/phoneIllustration.png';
import './App.css';

import Collapse from 'antd/lib/collapse';
import 'antd/lib/collapse/style/css'

import styled from 'styled-components';

const StyledCollapse = styled(Collapse)`
  width: 520px;
  margin-top: 5%;
  margin-bottom: 5%;
  @media (max-width: 700px) {
    max-width: 90%;
  }
`

const StyledPanel = styled(Collapse.Panel)`
  
  > .ant-collapse-content {
    /* background-color: #F3F3F3 !important; */
    padding-top: 10px;
  }
  
  > .ant-collapse-header { 
    color: #557CFF !important;
    background: #E8F2FF;
    border: 1px solid #557CFF;
  }
`

const AppWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 10%;
  align-items: center;
`

const Logo = styled.img`
  max-width: 300px;

  @media (max-width: 700px) {
    max-width: 200px;
  }
`

const PhotoIllustration = styled.img`
  max-width: 90%;
`

const Panel = Collapse.Panel;

class App extends Component {
  render() {
    const text = 'test'
    return <AppWrapper>
        <Logo src={phoneIllustration} alt="Dear You Screenshots!" />
        <Logo src={logo} alt="Dear You" />

        <StyledCollapse defaultActiveKey={['1']}>
          <StyledPanel header="These are real physical magical postcards?" key="1">
            <p>
              Yes! We print and mail the beautiful postcards you create on
              our iOS and Android apps.
            </p>
          </StyledPanel>
          <StyledPanel header="How much does it cost?" key="2">
            <p>
              Check out the latest price on the mobile app, but it's usually
              the price of half a cup of coffee.
            </p>
          </StyledPanel>
          <StyledPanel header="When will my postcard be delivered?" key="3">
            <p>
              You can track the latest status of your postcard on the "On The Way"
              tab.
            </p>
            <p>
              US Domestic delivery typically takes 4 to 6 business days and 
              international mailings take an additional 5 to 7 days*.
            </p>
            <p>
              *Delivery times quoted are estimates; our delivery partners use 
              USPS as their primary carrier and USPS experiences delays from 
              time to time. Note, undeliverable addresses or other issues can 
              prohibit the delivery of a postcard. Please contact us at 
              support (at) deshtiny.com if you have any questions.
            </p>
          </StyledPanel>
        </StyledCollapse>
      </AppWrapper>;
  }
}

export default App;
